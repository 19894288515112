.copyright .line {
  display: inline;
  padding-left: 0;
  text-indent: 0;
}
.copyright:not(:first-child) {
  margin-top: 0.5em;
}
.copyright-container {
  margin-top: 3em;
  font-size: 0.9em;
}
.preview .copyright-container {
  margin-top: 0;
}
