[data-theme='Gregory'] h1 {
  text-align: center;
  color: black;
  font-size: 1.3em;
  margin-bottom: 3em; }
  [data-theme='Gregory'] h1::before {
    content: none; }

[data-theme='Gregory'] h2 {
  font-size: 1.1em;
  text-align: center;
  font-style: normal;
  color: var(--highlightColour); }

[data-theme='Gregory'] h3 {
  font-size: 1em;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  color: var(--highlightColour); }

[data-theme='Gregory'] .people {
  padding-left: 0;
  text-indent: 0; }
  [data-theme='Gregory'] .people::before {
    content: none; }

[data-theme='Gregory'] .minister {
  margin-left: 0; }

[data-theme='Gregory'] .rubric {
  text-indent: 5mm;
  font-style: normal; }
  .preview .rubric {
    text-indent: 3mm; }

[data-theme='Gregory'] .psalm .verse {
  padding-left: 0; }

[data-theme='Gregory'] .psalm h2 + .people,
[data-theme='Gregory'] .psalm h3 + .people,
[data-theme='Gregory'] .psalm .people:first-child,
[data-theme='Gregory'] .psalm .people:last-child {
  margin-left: 0; }
  [data-theme='Gregory'] .psalm h2 + .people::before,
  [data-theme='Gregory'] .psalm h3 + .people::before,
  [data-theme='Gregory'] .psalm .people:first-child::before,
  [data-theme='Gregory'] .psalm .people:last-child::before {
    content: 'Ant.';
    display: inline-block;
    width: 10mm; }

[data-theme='Gregory'] .psalm .people {
  margin-left: 10mm; }

[data-theme='Gregory'] .psalm .verse {
  margin-left: 10mm; }

[data-theme='Gregory'] .preview .psalm .people:first-of-type,
[data-theme='Gregory'] .preview .psalm .people:last-child {
  margin-left: 0; }
  [data-theme='Gregory'] .preview .psalm .people:first-of-type::before,
  [data-theme='Gregory'] .preview .psalm .people:last-child::before {
    width: 6mm; }

[data-theme='Gregory'] .preview .psalm .people,
[data-theme='Gregory'] .preview .psalm .verse {
  margin-left: 6mm; }
