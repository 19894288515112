.psalm .verse {
  margin-top: 0.5em;
  padding-left: 10mm;
  break-inside: avoid;
}

.psalm .verse[data-verse-number] {
  text-indent: -10mm;
}

.psalm .verse[data-verse-number]::before {
  content: attr(data-verse-number);
  width: 10mm;
  display: block;
  float: left;
}

.psalm .antiphon {
  padding-left: 10mm;
  margin-top: 0.5em;
  font-style: italic;
  text-indent: -10mm;
}

.psalm .antiphon::before {
  content: var(--antiphonAbbr, 'Ant.');
  width: 10mm;
  color: var(--highlightColour);
  display: block;
  float: left;
}

.psalm .people::before {
  content: 'Ant.';
}

.psalm .verse + .people::before {
  content: 'All';
}

.psalm .people + .people {
  padding-left: 0;
}

.preview .psalm .verse {
  padding-left: 6mm;
}
.preview .psalm .verse[data-verse-number] {
  text-indent: -6mm;
}
.preview .psalm .verse[data-verse-number]::before {
  width: 6mm;
}
.preview .psalm .antiphon {
  padding-left: 6mm;
  text-indent: -6mm;
}
.preview .psalm .antiphon::before {
  width: 6mm;
}

.psalm .reference {
  margin-top: 0.5em;
  text-align: right;
  font-style: italic;
}
