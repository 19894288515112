.editable {
  position: relative;
  min-height: 20px;
}
.editable:hover {
  box-shadow: inset 0 0 10px 5px rgba(55, 161, 199, 1);
  transition-property: box-shadow;
  transition-duration: 0.1s;
}
.button {
  width: 25px;
  height: 25px;
  padding-top: 4px;
  position: absolute;
  border: 1px solid rgb(55, 161, 199);
  border-radius: 5mm;
  background: white;
  color: rgb(55, 161, 199);
  top: -2mm;
  display: none;
  cursor: pointer;
  font-family: var(--system-font);
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  z-index: 4;
  line-height: 1.04;
  box-sizing: border-box;
  font-size: 8pt;
}
.button::before {
  font-weight: bold;
  font-size: 1.3em;
  font-family: Symbola;
}
.button:hover::after {
  content: attr(word);
  display: block;
}
.editable:hover .button {
  display: block;
}
.button:hover {
  width: 60px;
  height: 32px;
  transition: width 0.3s, height 0.3s;
  z-index: 99;
}
.button[data-type='edit'] {
  right: 5mm;
}
.button[data-type='edit']::before {
  content: '\1F589 ';
  left: 1px;
  top: 4px;
}
.button[data-type='delete'] {
  right: 21mm;
}
.button[data-type='delete']::before {
  content: '\1F5D1 ';
  left: 5px;
  top: 4px;
}
.button[data-type='move-up'] {
  right: 29mm;
}
.button[data-type='move-up']::before {
  content: '\2B06 ';
  top: 4px;
  left: 4px;
}
.button[data-type='move-down'] {
  right: 37mm;
}
.button[data-type='move-down']::before {
  content: '\2B07 ';
  top: 4px;
  left: 4px;
}
.button[data-type='add'] {
  right: -8px;
  top: initial;
  bottom: 2px;
}
.button[data-type='add']::before {
  content: '➕ ';
  top: 5px;
  left: 4px;
}
.button[data-type='duplicate'] {
  right: 13mm;
}
.button[data-type='duplicate']::before {
  content: '🗐 ';
}
