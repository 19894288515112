[data-theme='Wesley'] .rubric {
  font-style: normal;
  margin-left: 5mm; }

[data-theme='Wesley'] h2 {
  font-style: normal;
  text-align: left;
  font-size: 1em;
  font-weight: normal;
  text-transform: uppercase; }

[data-theme='Wesley'] h3 {
  text-align: left;
  font-weight: normal;
  font-size: 1em;
  font-variant-caps: all-small-caps; }

[data-theme='Wesley'] h1 {
  margin: 1em 0;
  font-size: 1.1em;
  font-weight: normal;
  text-transform: uppercase;
  text-indent: 0;
  padding-left: 0; }
  [data-theme='Wesley'] h1::before {
    content: none; }

[data-theme='Wesley'] style + h1,
[data-theme='Wesley'] .cover + h1,
[data-theme='Wesley'] .button + h1,
[data-theme='Wesley'] h1:first-child {
  margin-top: 0; }

[data-theme='Wesley'] .responsorialPsalm .stanza {
  margin-left: 14mm; }
  .preview [data-theme='Wesley'] .responsorialPsalm .stanza {
    margin-left: 8.4mm; }

[data-theme='Wesley'] .responsorialPsalm .response {
  padding-left: 8mm;
  text-indent: -8mm;
  margin-left: 6mm; }
  [data-theme='Wesley'] .responsorialPsalm .response::before {
    width: 8mm; }

[data-theme='Wesley'] .minister {
  margin-left: 14mm; }
  preview [data-theme='Wesley'] .minister {
    margin-left: 8.4mm; }

[data-theme='Wesley'] .people {
  padding-left: 8mm;
  text-indent: -8mm;
  margin-left: 6mm; }
  [data-theme='Wesley'] .people::before {
    width: 8mm; }
  [data-theme='Wesley'] .people[data-no-all='true'] {
    text-indent: 0; }
  [data-theme='Wesley'] .people + .people::before {
    content: '\a0'; }
  [data-theme='Wesley'] .preview .people {
    padding-left: 4.8mm;
    text-indent: -4.8mm;
    margin-left: 3.6mm; }
    [data-theme='Wesley'] .preview .people::before {
      width: 4.8mm; }

[data-theme='Wesley'] .psalm .verse {
  padding-left: 14mm; }
  [data-theme='Wesley'] .psalm .verse[data-verse-number] {
    text-indent: -8mm; }
    [data-theme='Wesley'] .psalm .verse[data-verse-number]::before {
      width: 8mm; }

[data-theme='Wesley'] .preview .verse {
  padding-left: calc(14mm * 0.6); }
  [data-theme='Wesley'] .preview .verse[data-verse-number] {
    text-indent: calc(-8mm * 0.6); }
    [data-theme='Wesley'] .preview .verse[data-verse-number]::before {
      width: calc(8mm * 0.6); }
