.editable[data-type='minister'] + .people {
  margin-top: 0.1em;
}
.editable[data-type='minister'] + .editable[data-type='people'] > .people {
  margin-top: 0.1em;
}

.people {
  font-weight: bold;
  margin-top: 0.5em;
  display: block;
  padding-left: 10mm;
  text-indent: -10mm;
  break-inside: avoid;
}
.people::before {
  content: 'All';
  font-weight: normal;
  font-style: italic;
  float: left;
  display: block;
  width: 10mm;
  color: var(--highlightColour);
}
.preview .people {
  padding-left: 6mm;
  text-indent: -6mm;
}
.preview .people::before {
  width: 6mm;
}
.people + .people {
  text-indent: 0;
}
.people + .people::before {
  content: '';
}
.people[data-no-all='true'] {
  text-indent: 0;
}
.people[data-no-all='true']::before {
  content: '';
}
.minister + .people {
  margin-top: 0.1em;
}
.minister-container + .people {
  margin-top: 0.1em;
}
.people.noindent .line {
  text-indent: 0;
  padding-left: 0;
}
