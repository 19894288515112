.dialogueButton {
  height: 13px;
  border-radius: 25px;
  background: white;
  margin-right: 10px;
  padding: 5px 10px;
  line-height: 0.9;
  border: 2px solid var(--system-color);
  box-sizing: content-box;
  cursor: pointer;
}

.dialogueButton:hover, .dialogueButton:focus {
  box-shadow: 0px 0px 3px 1px var(--system-color);
  transition: box-shadow 0.2s;
}
