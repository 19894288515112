.addDialogue button {
  display: block;
  float: left;
  height: 13px;
  border-radius: 25px;
  background: white;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
  line-height: 0.9;
  border: 3px solid var(--system-color);
  box-sizing: content-box;
  cursor: pointer;
  font-family: var(--system-font);
}

.addDialogue button:hover, .addDialogue button:focus {
  box-shadow: 0px 0px 3px 1px var(--system-color);
  transition: box-shadow 0.2s;
}

.addDialogue .addOptions {
  margin-top: 0.5em;
}

.addDialogue {
  font-family: var(--system-font);
  grid-row-start: span 2;
  grid-column-start: span 2;
  margin: 10px;
}
