.modal {
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  overflow: hidden;
}

.modal-content {
  background-color: #fefefe;
  border: 1px solid #888;
  border-radius: 10px;
  width: 80%; /* Could be more or less, depending on screen size */
  overflow: auto;
  max-height: 80%;
  min-height: 50%;
  top: 10%;
  left: 10%;
  position: absolute;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 338px;
  grid-template-rows: 44px max-content 1fr 39px;
  grid-template-areas: "title title" "summary summary" "content preview" "buttons buttons";
  overflow: hidden;
  box-shadow: 3px 3px 5px 1px #555;
  font-family: var(--fontFamily);
}

.modal-title {
  background: var(--system-color);
  color: white;
  font-family: var(--system-font);
  padding: 10px;
  font-size: 1.2em;
  font-weight: bold;
  grid-area: title;
}
