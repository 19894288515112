.hymn {
  display: block;
  margin: 0.5em auto 0;
  text-align: left;
  width: max-content;
}

.hymn:first-child {
  margin-top: 0;
}

.hymn .stanza {
  margin-top: 0.5em;
  display: block;
}

.hymn .reference {
  display: block;
  margin-top: 0.5em;
  text-align: right;
  font-style: italic;
  break-before: avoid;
}

.hymn .stanza {
  break-inside: avoid;
}
