.cover {
  display: none; /*Hide cover in edit screen (for now)*/
}

.preview .cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 148mm;
  height: 210mm;
  box-sizing: border-box;
  margin: 0 auto;
  background: white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40mm 50mm 30mm 50mm 40mm;
  grid-template-areas: "churchName" "serviceTitle" "serviceSubTitle" "dateTime" "strapline";
}
.cover .churchName {
  grid-area: churchName;
  font-style: italic;
  font-size: 1.2em;
  text-align: center;
  margin-top: 10mm;
}
.cover .serviceTitle {
  grid-area: serviceTitle;
  background: var(--highlightColour);
  color: white;
  font-weight: bold;
  font-size: 2.2em;
  margin: 0 10mm;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cover .serviceSubTitle { 
  grid-area: serviceSubTitle;
  margin-top: 5mm;
  text-align: center;
  font-weight: bold;
  font-style: italic;
  font-size: 1.6em; 
}

.cover .dateTime {
  grid-area: dateTime;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
}
.cover .strapline {
  grid-area: strapline;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 10mm;
  font-style: italic;
}
