@charset "UTF-8";
:root {
  --highlightColour: darkred;
  --fontFamily: 'Crimson Text';
  --fontSize: 12.5pt;
}

@page {
  size: A5;
  margin: 10mm;
  margin-bottom: 15mm;
  @bottom-center {
    font-style: italic;
    padding-bottom: 5mm;
  }
}
@page :right {
  margin-left: 8mm;
}
@page :left {
  margin-right: 8mm;
}
@page cover {
  @bottom-center {
    content: none;
  }
}
@page service {
  @bottom-center {
    content: '– ' counter(page) ' –';
  }
}
.serviceContent {
  font-size: var(--fontSize);
  font-family: var(--fontFamily);
  line-height: 1.05;
}

.service {
  width: 130mm;
  margin: 0 auto;
  border: 1px solid hsl(0, 0%, 70%);
  padding: 10mm;
}

.rubric {
  color: var(--highlightColour);
  font-style: italic;
  display: block;
  margin-top: 0.5em;
  text-align: left;
}

h1,
h2,
h3 {
  font-family: var(--headingFontFamily);
}

h2 {
  font-style: italic;
  text-align: right;
  margin: 10pt 0;
}
h2:first-child {
  margin-top: 0;
}

h3 {
  text-align: right;
  margin: 10pt 0;
}
h3:first-child {
  margin-top: 0;
}
style:first-child + * {
  break-before: auto;
}
h1 {
  color: var(--highlightColour);
  break-before: always;
  border-top: 2px dashed var(--system-color);
  margin: 0 0 10pt;
  text-indent: -10mm;
  padding-left: 10mm;
  margin-top: 1em;
}
h1::before {
  content: '\B6';
  display: inline-block;
  width: 10mm;
  text-indent: 0;
}
.preview h1 {
  text-indent: -6mm;
  padding-left: 6mm;
}
.preview h1::before {
  width: 6mm;
}
style + h1,
.cover + h1,
.button + h1,
h1:first-child {
  margin-top: 0;
}
.responsorialPsalm {
  display: block;
  margin-top: 0.5em;
}

.responsorialPsalm .stanza {
  margin-left: 10mm;
}
.preview .responsorialPsalm .stanza {
  margin-left: 6mm;
}
.stanza + .stanza {
  margin-top: 0.5em;
}

.responsorialPsalm .response {
  font-weight: bold;
  margin: 0.5em 0;
  padding-left: 10mm;
  text-indent: -10mm;
}
.responsorialPsalm .response::before {
  content: 'R';
  width: 10mm;
  display: inline-block;
  float: left;
  color: var(--highlightColour);
}
.preview .responsorialPsalm .response {
  padding-left: 6mm;
  text-indent: -6mm;
}
.preview .responsorialPsalm .response::before {
  width: 6mm;
}
.scripturereference {
  text-align: right;
  margin: 0.5em 0;
  font-style: italic;
}

.breakbefore {
  break-before: always;
  border-top: 2px dashed var(--system-color);
}

.right {
  break-before: right;
}

.line {
  display: inline-block;
  padding-left: 1em;
  text-indent: -1em;
}

.rubric .line {
  display: contents;
}

.indent {
  display: inline-block;
  width: 1em;
}
h1 .indent,
h2 .indent,
h3 .indent,
.rubric .indent {
  display: none;
}
.reading {
  margin-top: 10pt;
}
