:root {
  --system-font: Amaranth;
  --system-color: #5a86a8;
}

#app iframe.edited {
  /*   opacity: 0.5; */
}

html,
body,
.grid-container {
  height: 100%;
  margin: 0;
  overflow: hidden;
  font-size: 14pt;
}

.grid-container > *:not(.modal) {
  position: relative;
}

.grid-container {
  display: grid;
  grid-template-columns: 80px 1.7fr 1.6fr;
  grid-template-rows: 75px calc(100vh - 75px);
  grid-template-areas:
    'title  title  title'
    'tools  edit   preview';
}
.edit {
  grid-area: edit;
  border-right: 1px solid;
  overflow: auto;
  background: gray;
}
.edit.active {
  background: hsl(0, 7%, 50%);
}
.preview {
  grid-area: preview;
  overflow: auto;
  background-image: linear-gradient(45deg, #c0c0c0 25%, transparent 25%),
    linear-gradient(-45deg, #c0c0c0 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #c0c0c0 75%),
    linear-gradient(-45deg, transparent 75%, #c0c0c0 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}
.preview.edited {
  overflow: hidden;
}
.title {
  grid-area: title;
  color: white;
  font-size: 2.5em;
  font-weight: bold;
  font-family: var(--system-font);
  background: linear-gradient(to bottom, #1e5799 0%, var(--system-color) 80%);
  padding: 5px;
  overflow: hidden;
}
.title em {
  color: hsl(63, 10%, 70%);
}
@media (max-width: 750px) {
  .title em {
    display: none;
  }
}
@media (max-width: 600px) {
  .grid-container {
    grid-template-columns: 80px 1fr;
    grid-template-rows: 45px calc(100vh - 45px);
    grid-template-areas:
      'title  title'
      'edit  edit';
  }
  .preview,
  .tools {
    display: none;
  }
  .title {
    font-size: 1.5em;
  }
  .service {
    width: unset;
    padding: 2mm;
  }
  .serviceContent {
    margin-bottom: 30mm;
  }
  .paneTitle {
    display: none;
  }
  h1,
  .breakbefore {
    border-top: none;
  }
}
.tools {
  grid-area: tools;
  background: #5a86a8;
}

iframe {
  width: calc(155mm + 20px);
  height: calc(210mm + 20px);
  border: 1px solid;
  margin: 0 auto;
  display: block;
  background: white;
}
.service {
  background: white;
  margin-bottom: 5pt;
  position: relative;
}

.paneTitle {
  font-family: var(--system-font);
  font-size: smaller;
  text-align: center;
  width: 100px;
  border: 1px solid black;
  border-radius: 5px;
  background: hsl(0, 0%, 30%);
  margin: 5px auto 5px;
  color: white;
  padding: 1px 0;
}

.btnPreview {
  display: block;
  margin: 10pt auto 0;
  font-family: var(--system-font);
  width: 100px;
  height: 40px;
  border-radius: 20px;
  background: transparent;
  color: white;
  font-size: 0.8em;
  cursor: pointer;
}

.btnPreview:hover,
.btnPreview:focus {
  box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 1);
  outline: 0;
}

.beforeFirst {
  position: relative;
}

.edit.active .beforeFirst .button {
  display: block;
}
