label {
  width: 20%;
  vertical-align: top;
  font-family: var(--system-font);
  padding-bottom: 0.5em;
}
textarea {
  width: 100%;
  height: 70mm;
  resize: none;
  font-family: inherit;
  line-height: inherit;
  font-size: 0.9em;
}
input[type='text'] {
  width: 80%;
}

.modal-options {
  grid-area: content;
  padding: 10px;
  display: grid;
  grid-template-columns: 2fr 2em 3fr;
  grid-template-rows: max-content;
  grid-gap: 0.6em 0.6em;
  grid-auto-flow: dense;
  align-items: center;
  overflow-y: auto;
}
.modal-options :last-child {
  margin-bottom: 10px;
}

input[type='checkbox'],
input[type='radio'] {
  grid-column: 2 / 4;
  justify-self: self-start;
  align-self: self-start;
  margin: 0;
}

label,
input[type='checkbox'] + label,
input[type='radio'] + label {
  width: auto;
  padding: 0;
  margin: 0;
  align-self: self-start;
  text-align: right;
  height: 100%;
}
textarea + label {
  align-self: self-start;
}

input,
output,
textarea,
select,
button,
.optionDisplay {
  grid-column: 2 / 4;
  width: auto;
  margin: 0;
  align-self: self-start;
}

.modal-buttons {
  text-align: right;
  grid-area: buttons;
  background: hsla(206, 31%, 85%, 1);
  margin: 0;
  padding-top: 5px;
}

.modal-preview {
  border: 2px solid var(--system-color);
  padding: 0.5em;
  border-radius: 5px;
  overflow: auto;
  line-height: 1.05;
  grid-area: preview;
  margin: 10px;
}

.modal-preview.flash {
  animation: flash 0.5s;
}
.modal-preview.flashA {
  animation: flashA 0.5s;
}

.modal-preview .preview {
  width: 290px;
  font-size: 60%;
  background: inherit;
  overflow: visible;
}

.modal-preview .preview > * {
  margin-bottom: 0.5em;
}

.modal-summary {
  grid-area: summary;
  font-family: var(--system-font);
  padding: 10px;
  font-size: 0.8em;
  background: hsla(206, 31%, 85%, 1);
}

@keyframes flash {
  1% {
    border-color: hsl(0, 100%, 38%);
  }
  100% {
    border-color: var(--system-color);
  }
}
@keyframes flashA {
  1% {
    border-color: hsl(0, 100%, 38%);
  }
  100% {
    border-color: var(--system-color);
  }
}

@media only screen and (max-width: 1150px) {
  .modal-content {
    grid-template-columns: 1fr;
    grid-template-areas: 'title' 'summary' 'content' 'buttons';
  }
  .modal-preview {
    display: none;
  }
}
